<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>

      <h1>Rinder</h1>
      <p>
        Unser Vieh ist gemischtrassig und die Tiere leben in einer kompletten Herde. Das heißt, wir halten die Bullen,
        Kühe und Kälber zusammen. Wir haben keinen Stall. Die Tiere leben das ganze Jahr über im Freien. Durch die
        große Anzahl Bäume gibt es viel natürlichen Schutz.
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-04-25_20-15-53.jpg">
        <img src="../../assets/images/2022-04-25_20-15-53-640.jpg">
      </picture>

      <p>
        Wir haben keine "Milchkühe", d. h. eine Kuh, die viel mehr Milch produziert, als für die Ernährung von ein
        oder zwei Kälbern nötig ist.
      </p>

      <h2>Entwöhnung</h2>
      <p>
        Wir entwöhnen unsere Kälber nicht. Stattdessen lassen wir die Kühe und Kälber entscheiden, wann es an der Zeit
        ist, keine Milch mehr zu trinken. Es gibt auch keine Trennung der Kälber von den Kühen.
      </p>

      <CattleHealthIssues />

      <div v-if="isEnabled(LIST_ANIMALS)">
        <h2>Unsere Rinder</h2>
        <p>
          Im Moment haben wir {{ animals.length }} Rinder auf unserem Land.
        </p>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Alter</th>
              <th>Geschlecht</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="animal in animals" :key="animal.externalId">
              <td>{{ animal.externalId }}</td>
              <td nowrap>{{ $n(animal.ageInMonths / 12, 'decimal') }} Jahre</td>
              <td>{{ $t('animalListing.' + animal.sex) }}</td>
              <td nowrap>{{ $t('terms.' + animal.lifeStatus.toLowerCase()) }} <span v-if="animal.readyForSale">( {{
                $t('animalListing.forSale') }} )</span></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script setup>
/*eslint no-unused-vars: "off"*/
import { useLoadAnimals } from '@/animals'
import { isEnabled, LIST_ANIMALS, SPONSOR_ANIMALS } from '@/features'
import CattleHealthIssues from './CattleHealthIssues.vue'

const { animals, error } = useLoadAnimals('CATTLE', 'MIXED')
</script>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  name: "CattleDE",
  components: {
    Sidebar, CattleHealthIssues
  },

  computed: {
    animalCount () {
      return this.animals.length
    }
  },

  methods: {
    buyForLife (animalId) {
      this.$router.push({ name: 'buyForLife', params: { animalId } })
    },

    buyForMeat (animalId) {
      this.$router.push({ name: 'buyForMeat', params: { animalId } })
    },

  }
};
</script>

<style scoped>
TABLE {
  border-collapse: separate;
  border-spacing: 20px 0;
}

TH {
  text-align: left;
  white-space: nowrap;
}
</style>
