<template>
  <div>
    <h2>Gesundheitsrisiken</h2>
    <p>Wir versuchen in unserem Projekt so transparent wie möglich zu sein und dazu gehört auch Aufklärung über Dinge,
      die vielen Menschen sicher nicht bekannt sind. Wie alle Lebewesen können auch unsere Rinder krank werden und in
      manchen Fällen gibt es keine Heilung, sondern zum Schutz des Restes der Herde wird vom Amtstierarzt die
      Schlachtung der erkrankten oder infizierten Tiere angeordnet.</p>
    <p><strong>In vielen Fällen wird vom Amtstierarzt im Schlachthof das Fleisch dieser Tiere für den menschlichen
        Verkehr freigegeben.</strong> Dort findet die Fleischbeschau statt und es werden u.a. die Organe des Tieres
      intensiv untersucht.
    </p>
    <p>Hier in der Provinz Cordoba in Andalusien gibt es genau einen dafür zugelassenen Schlachthof. Dort arbeiten 5
      Schlachter und der Amtstierarzt. Es wird ein Rind zu einer Zeit per Bolzenschuß betäubt und dann geschlachtet. Die
      geringe Menge an Tieren - so um die 20 an einem Tag oder weniger - hilft dabei sorgfältig und korrekt vorzugehen.
    </p>

    <router-link :to="{ name: 'BovineIllnesses' }">Mehr Details ...</router-link>
  </div>
</template>
